import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"

export const ResumoProdutos = styled.section`
  background-color: var(--back-grey);
  padding-bottom: 10rem;
  padding-top: 1rem;

  ${media.lessThan("large")`
   padding-bottom: 2rem;
  `}
`
export const ResumoProdutosWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
  `}
`
export const ResumoProdutosTitle = styled.h1`
  font-size: 2rem;
  border-bottom: 1px solid var(--black);
  display: block;
  width: 17rem;
  text-align: center;
  font-weight: 700;
  padding: 1.5rem 2rem;
  margin: 3rem auto 6rem;
  text-transform: uppercase;
`
export const ResumoProdutosText = styled.p`
  width: 60%;
  text-align: center;
  margin: 0 auto 5rem;
  color: var(--text);
  font-family: "Lato", sans-serif;
  line-height: 2;
`

export const ResumoProdutosMenu = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 0 auto 5rem;

  ${media.lessThan("large")`
    flex-direction: column;
    margin: 0 auto 1rem;
  `}
`

export const ResumoProdutosMenuItem = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.active {
    color: var(--white);
    border: 1px solid var(--orange);
    background-color: var(--orange);
    box-shadow: 0px 3px 11px var(--black);
  }

  ${media.lessThan("large")`
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  `}
`

export const ResumoProdutosImgContainer = styled.div`
  display: flex;
  width: 60%;
  margin: 0 auto 5rem;
  justify-content: space-around;

  ${media.lessThan("large")`
    flex-direction: column;
    width: 80%;
  `}
`

export const ResumoProdutosImgBox = styled.div`
  width: 45%;

  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 1rem;
  `}
`

export const ResumoProdutosImg = styled.img`
  width: 100%;
  height: auto;
`

export const ResumoProdutosImgText = styled.p`
  display: block;
  background-color: var(--grey);
  color: var(--white);
  padding: 1.5rem 2rem;
  text-transform: uppercase;
  text-align: center;
`

export const ResumoProdutosLink = styled(Link)`
  color: var(--black);
  border: 1px solid var(--black);
  background-color: transparent;
  padding: 1rem 2.75rem;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  text-align: center;
  width: 10rem;
  text-decoration: none;
`

import styled from "styled-components"
import media from "styled-media-query"

export const MVV = styled.section`
  padding-bottom: 10rem;

  ${media.lessThan("large")`
    padding-bottom: 2rem;
  `}
`

export const MVVWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    padding: .5rem;
  `}
`
export const MVVWrapperBox = styled.div`
  width: 27%;
  padding: 2rem;
  border: 1px solid var(--text);
  border-radius: 1rem;

  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 3rem;
  `}
`

export const MVVTitle = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
`

export const MVVText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 2;
  color: var(--text);
`

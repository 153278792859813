import React from "react"
import PropTypes from "prop-types"
import { Email } from "@styled-icons/material/Email"
import { Phone } from "@styled-icons/entypo/Phone"
import { Home } from "@styled-icons/boxicons-solid/Home"

import * as S from "./styled"

const Contato = ({ title }) => {
  return (
    <S.Contato>
      <S.ContatoWrapper>
        <S.ContatoTitle display={title ? 0 : 1}>Contato</S.ContatoTitle>
        <S.ContatoHeader display={title ? 1 : 0}>
          <S.ContatoHeaderTitle>Contato</S.ContatoHeaderTitle>
          <S.ContatoHr />
        </S.ContatoHeader>
        <S.ContatoContainer>
          <S.ContatoBox>
            <S.ContatoTextBox>
              <Email className="ContatoIcon" />
              <S.ContatoText>
                vendas@acoconsp.com.br / administrativo@acoconsp.com.br /
                juarez@acoconsp.com.br
              </S.ContatoText>
            </S.ContatoTextBox>
            <S.ContatoTextBox>
              <Phone className="ContatoIcon" />
              <S.ContatoText>(19) 3352-7759</S.ContatoText>
            </S.ContatoTextBox>
            <S.ContatoTextBox>
              <Home className="ContatoIcon" />
              <S.ContatoText>
                Rua Paulo Américo Russo, 759, CP 13607-232 - Araras/SP
              </S.ContatoText>
            </S.ContatoTextBox>
            <S.ContatoMap>
              <iframe
                title="Localização"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.624956754799!2d-47.362266474432836!3d-22.367785781286187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c870af917ef16f%3A0x678e5dd3e10b6976!2zQcOnb2Nvbg!5e0!3m2!1sen!2sbr!4v1600803186889!5m2!1sen!2sbr"
                width="100%"
                height="300"
                frameBorder="0"
                aria-hidden="false"
              ></iframe>
            </S.ContatoMap>
          </S.ContatoBox>
          <S.ContatoBox>
            <S.ContatoForm
              action="http://novo.acoconsp.com.br/send-email.php"
              method="POST"
            >
              <S.ContatoInput
                type="text"
                name="nome"
                placeholder="Nome..."
              ></S.ContatoInput>
              <S.ContatoInput
                type="email"
                name="email"
                placeholder="Email..."
              ></S.ContatoInput>
              <S.ContatoInput
                type="text"
                name="assunto"
                placeholder="Assunto..."
              ></S.ContatoInput>
              <S.ContatoTextArea
                name="mensagem"
                placeholder="Mensagem..."
                rows="15"
              />
              <S.ContatoFormButton type="submit" name="enviar-form">
                Enviar
              </S.ContatoFormButton>
            </S.ContatoForm>
          </S.ContatoBox>
        </S.ContatoContainer>
      </S.ContatoWrapper>
    </S.Contato>
  )
}

Contato.propTypes = {
  title: PropTypes.bool.isRequired,
}

export default Contato

import styled from "styled-components"
import media from "styled-media-query"

export const HighlightWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background: url("img/slider/slider03.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60vh;
  justify-content: flex-end;
  align-items: center;

  ${media.lessThan("large")`
    padding-top: 4rem;
    height: 70vh;
  `}
`

export const HighlightTitle = styled.h1`
  font-size: 2rem;
  color: var(--white);
  text-transform: uppercase;
  font-weight: 700;
  padding: 1rem;
`

export const Highlight = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: flex-end;
`

export const HighlightLink = styled.a`
  display: inline-block;
  background-color: var(--orange);
  color: var(--white);
  padding: 1.5rem 3rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
`

import React from "react"

import * as S from "./styled"

const Highlight = () => {
  return (
    <>
      <S.HighlightWrapper>
        {/* <S.HighlightTitle>O melhor parceiro</S.HighlightTitle> */}
        {/* <S.HighlightTitle>para seu projeto</S.HighlightTitle> */}
      </S.HighlightWrapper>
      <S.Highlight>
        <S.HighlightLink target="_blank" href="https://wa.me/55019998107579">
          Faça seu orçamento
        </S.HighlightLink>
      </S.Highlight>
    </>
  )
}

export default Highlight

import React from "react"

import * as S from "./styled"

const CallToAction = () => {
  return (
    <S.CallToAction>
      <S.CallToActionWrapper>
        <S.CallToActionText>
          Se interessou por nossos produtos? Faça já seu orçamento!
        </S.CallToActionText>
        <S.CallToActionButton
          target="_blank"
          href="https://wa.me/55019998347135"
        >
          Clique Aqui
        </S.CallToActionButton>
      </S.CallToActionWrapper>
    </S.CallToAction>
  )
}

export default CallToAction

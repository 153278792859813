import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"
import { Link } from "gatsby"

export const ResumoSobre = styled.section`
  background-color: var(--white);
  padding-bottom: 10rem;

  ${media.lessThan(`large`)`
    padding-bottom: 2rem;
  `}
`

export const ResumoWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;

  ${media.lessThan(`large`)`
    width: 100%;
    min-width: 100%;
  `}
`

export const ResumoTitle = styled.h1`
  font-size: 2rem;
  border-bottom: 1px solid var(--black);
  display: block;
  width: 17rem;
  text-align: center;
  font-weight: 700;
  padding: 1.5rem 2rem;
  margin: 3rem auto 6rem;
  text-transform: uppercase;
`

export const ResumoSubWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan("large")`
    flex-direction: column;
    padding: .5rem;
  `}
`

export const ResumoBox = styled.div`
  width: 40%;

  ${media.lessThan(`large`)`
    width: 100%;
  `}
`

export const ResumoImg = styled(Img)`
  width: 100%;
  height: auto;
`

export const ResumoSubtitle = styled.h2`
  margin: 3rem 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
`

export const ResumoText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 2;
  color: var(--text);
`

export const ResumoLink = styled(Link)`
  display: block;
  color: var(--text);
  text-transform: uppercase;
  margin: 1.5rem 0;
  font-weight: 700;
  text-decoration: none;
`

import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ResumoSobre from "../components/ResumoSobre"
import Highlight from "../components/Highlight"
import MVV from "../components/MVV"
import ResumoProdutos from "../components/ResumoProdutos"
import CallToAction from "../components/CallToAction"
import Contato from "../components/Contato"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Highlight />
      <ResumoSobre />
      <MVV />
      <ResumoProdutos />
      <CallToAction />
      <Contato title={false} />
    </Layout>
  )
}

export default IndexPage

import React, { useState } from "react"

import * as S from "./styled"

const ResumoProdutos = () => {
  const [active, setActive] = useState("Arames")
  const [produtos, setProdutos] = useState([
    { name: "Arame recozido 12", photo: "img/produtos/arames/recozido12.jpg" },
    { name: "Arame recozido 18", photo: "img/produtos/arames/recozido18.jpg" },
  ])
  const buttons = [
    "Arames",
    "Telas e Malhas",
    "Treliças",
    "Ferragem Armada",
    "Vergalhões",
  ]

  function someFunct(name) {
    setActive(name)
    if (name === "Arames") {
      setProdutos([
        {
          name: "Arame recozido 12",
          photo: "img/produtos/arames/recozido12.jpg",
        },
        {
          name: "Arame recozido 18",
          photo: "img/produtos/arames/recozido18.jpg",
        },
      ])
    }
    if (name === "Telas e Malhas") {
      setProdutos([
        {
          name: "Malha Pop Leve (20 X 20 X 3,4) (2,00 X 3,00)",
          photo: "img/produtos/malhas/malha.jpg",
        },
        {
          name: "Tela Nervurada Q92",
          photo: "img/produtos/telas/tela.jpg",
        },
      ])
    }
    if (name === "Treliças") {
      setProdutos([
        {
          name: "Treliças H8",
          photo: "img/produtos/trelicas/trelica.jpg",
        },
        {
          name: "Treliças H12",
          photo: "img/produtos/trelicas/trelica.jpg",
        },
      ])
    }
    if (name === "Ferragem Armada") {
      setProdutos([
        {
          name: "Ferragem Armada",
          photo: "img/produtos/ferragem/ferragem01.jpg",
        },
        {
          name: "Ferragem Armada",
          photo: "img/produtos/ferragem/ferragem02.jpg",
        },
      ])
    }
    if (name === "Vergalhões") {
      setProdutos([
        {
          name: "Vergalhão barra redonda 10mm lisa",
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
        {
          name: "Vergalhão CA 50 DN 6,3 mm (1/4”)",
          photo: "img/produtos/vergalhao/vergalhao.jpg",
        },
      ])
    }
  }
  return (
    <S.ResumoProdutos>
      <S.ResumoProdutosWrapper>
        <S.ResumoProdutosTitle>Produtos</S.ResumoProdutosTitle>
        <S.ResumoProdutosText>
          A Açocon também se destaca na comercialização de produtos que, além de
          referência no mercado brasileiro, concorrem igualmente com as mais
          avançadas novidades internacionais.
        </S.ResumoProdutosText>
        <S.ResumoProdutosMenu>
          {buttons.map((name, index) => {
            return (
              <S.ResumoProdutosMenuItem
                key={index}
                onClick={() => someFunct(name)}
                className={active === name ? "active" : ""}
              >
                {name}
              </S.ResumoProdutosMenuItem>
            )
          })}
        </S.ResumoProdutosMenu>
        <S.ResumoProdutosImgContainer>
          {produtos.map((item, index) => {
            return (
              <S.ResumoProdutosImgBox key={index}>
                <S.ResumoProdutosImg src={item.photo} alt="" />
                <S.ResumoProdutosImgText>{item.name}</S.ResumoProdutosImgText>
              </S.ResumoProdutosImgBox>
            )
          })}
        </S.ResumoProdutosImgContainer>
        <S.ResumoProdutosLink to="/produtos">Ver mais</S.ResumoProdutosLink>
      </S.ResumoProdutosWrapper>
    </S.ResumoProdutos>
  )
}

export default ResumoProdutos

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const ResumoSobre = () => {
  const { imgEmpresa, imgCliente } = useStaticQuery(graphql`
    query {
      imgEmpresa: file(relativePath: { eq: "home/resumo-empresa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgCliente: file(relativePath: { eq: "home/resumo-clientes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.ResumoSobre>
      <S.ResumoWrapper>
        <S.ResumoTitle>Sobre Nós</S.ResumoTitle>
        <S.ResumoSubWrapper>
          <S.ResumoBox>
            <S.ResumoImg fluid={imgEmpresa.childImageSharp.fluid} alt="" />
            <S.ResumoSubtitle>A Empresa</S.ResumoSubtitle>
            <S.ResumoText>
              A Açocon conta com estrutura e equipe especializada para atender e
              executar os mais diversos serviços, sempre comprometidos com a
              qualidade e com as necessidades dos nossos clientes. Atendemos
              Araras e Região.
            </S.ResumoText>
            <S.ResumoLink to="/empresa">Saiba mais -&gt;</S.ResumoLink>
          </S.ResumoBox>
          <S.ResumoBox>
            <S.ResumoImg fluid={imgCliente.childImageSharp.fluid} alt="" />
            <S.ResumoSubtitle>Nossos Clientes</S.ResumoSubtitle>
            <S.ResumoText>
              O comprometimento com os prazos de entrega, a qualidade dos seus
              serviços, e uma politica de preços altamente competitiva, fazem
              com que a Açocon seja a garantia de uma parceria bem estruturada
              para o seu projeto.
            </S.ResumoText>
            <S.ResumoLink to="/clientes">Saiba mais -&gt;</S.ResumoLink>
          </S.ResumoBox>
        </S.ResumoSubWrapper>
      </S.ResumoWrapper>
    </S.ResumoSobre>
  )
}

export default ResumoSobre

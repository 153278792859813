import styled from "styled-components"
import media from "styled-media-query"

export const Contato = styled.section`
  padding-bottom: 10rem;
  padding-top: 1rem;

  ${media.lessThan(`large`)`
    padding-bottom: 2rem;
  `}
`
export const ContatoWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
  `}
`
export const ContatoHeader = styled.div`
  display: ${props => (props.display ? "block" : "none")};
  width: 100%;
  margin: 4rem auto 8rem 0;
  padding: 1.5rem 2rem 1.5rem 0;
`
export const ContatoHeaderTitle = styled.h1`
  font-size: 2rem;
  text-align: left;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export const ContatoHr = styled.hr`
  width: 3rem;
  height: 1px;
  background: var(--black);
  text-align: left;
  margin: 0;
`

export const ContatoTitle = styled.h1`
  display: ${props => (props.display ? "block" : "none")};
  font-size: 2rem;
  border-bottom: 1px solid var(--black);
  width: 17rem;
  text-align: center;
  font-weight: 700;
  padding: 1.5rem 2rem;
  margin: 3rem auto 6rem;
  text-transform: uppercase;
`
export const ContatoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Lato", sans-serif;

  ${media.lessThan(`large`)`
    flex-direction: column;
    padding: .5rem;
  `}
`

export const ContatoBox = styled.div`
  width: 47.5%;

  ${media.lessThan(`large`)`
    width: 100%;
    margin-bottom: 1.5rem;
  `}
`
export const ContatoTextBox = styled.div`
  display: flex;

  & .ContatoIcon {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
`

export const ContatoText = styled.p`
  color: var(--text);
  margin: 0.75rem 0;
`

export const ContatoMap = styled.div``

export const ContatoForm = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const ContatoInput = styled.input`
  display: block;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-family: "Lato", sans-serif;
  border: 1px solid var(--black);
  box-sizing: border-box;
`

export const ContatoTextArea = styled.textarea`
  font-family: "Lato", sans-serif;
  display: block;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
`

export const ContatoFormButton = styled.button`
  color: var(--white);
  background-color: var(--orange);
  border: none;
  padding: 1rem 2.5rem;
  margin-top: 1.5rem;
  margin-left: auto;
  text-transform: uppercase;
  cursor: pointer;
`

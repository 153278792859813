import React from "react"

import * as S from "./styled"
const MVV = () => {
  return (
    <S.MVV>
      <S.MVVWrapper>
        <S.MVVWrapperBox>
          <S.MVVTitle>Missão</S.MVVTitle>
          <S.MVVText>
            Oferecer produtos de qualidade que atendam às necessidades dos
            clientes, trazendo benefícios para todos os que participam do
            negócio, e manter a empresa competitiva no seu ramo de atuação,
            através da busca da melhor tecnologia disponível e tendo consciência
            da importante preservação do meio ambiente.
          </S.MVVText>
        </S.MVVWrapperBox>
        <S.MVVWrapperBox>
          <S.MVVTitle>Visão</S.MVVTitle>
          <S.MVVText>
            Ser referência na comercialização e distribuição de aço.
          </S.MVVText>
        </S.MVVWrapperBox>
        <S.MVVWrapperBox>
          <S.MVVTitle>Valores</S.MVVTitle>
          <S.MVVText>
            - Compromisso com o bem estar de seus colaboradores e clientes{" "}
            <br />
            - Empreendedorismo com agilidade <br /> - Responsabilidade Social.
          </S.MVVText>
        </S.MVVWrapperBox>
      </S.MVVWrapper>
    </S.MVV>
  )
}

export default MVV

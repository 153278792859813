import styled from "styled-components"
import media from "styled-media-query"

export const CallToAction = styled.section`
  background-color: var(--black);
`
export const CallToActionWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    padding: 2rem 5rem;
  `}
`
export const CallToActionText = styled.p`
  color: var(--white);
  text-transform: uppercase;

  ${media.lessThan("large")`
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.5;
  `}
`

export const CallToActionButton = styled.a`
  text-decoration: none;
  margin-left: 2rem;
  background-color: var(--orange);
  border-radius: 1rem;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  color: var(--white);
  border: none;

  ${media.lessThan("large")`
    text-align: center;
    margin: 0 auto;
  `}
`
